import React, { Component } from 'react';
import logo from './pictures/logo.png';
import laser from './pictures/laser.svg';

import './Libraries/blob'
import Mokup from './plaquette_abcs.pdf'

import './App.scss';

class App extends Component {

  constructor() {
    super()
    this.state = {
      infoText:(<span>&nbsp;</span>),
      infoEmail:(<span>&nbsp;</span>),
      isLoading:false,
      sended:false
    }
  }

  // isValid() {
  //   return !this.state.errorEmail
  //           && !this.state.errorText
  //           && this.state.email
  //           && this.state.text
  //
  // }
  //
  // changeText(element){
  //   var error = null
  //   var info = null
  //   let min = 10
  //   let max = 1200
  //   if (element) {
  //     if (element.length > max) {
  //       error = "Trop long !"
  //     } else if (element.length < min) {
  //       error = "Minimum " + min + " caractères, encore " + (min - element.length) + " !"
  //     } else if (!element.match(/^[0-9a-zA-ZÀ-ÿ’ʼ'!?+&^.@%,:=+/$~;#\r\n" ()_-]*$/i)) {
  //       var unallowedChar = Array.from(new Set(element.match(/((?![0-9a-zA-ZÀ-ÿ’ʼ'!?+&^.@%,:=+/$~;#\r\n" ()_-]).)/ig)));
  //       error = "Interdit : " + unallowedChar.join(' ')
  //     } else {
  //       info = (max - element.length)
  //     }
  //   } else {
  //     error = "Obligatoire !"
  //   }
  //   this.setState({text:element, errorText: error, infoText:info, errorGlobal:null})
  // }
  //
  // changeEmail(element) {
  //   if (element) {
  //     this.setState({
  //       email: (element ? element : undefined),
  //       errorEmail: (element && element.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i) === null ? "L'email n'est pas valide" : undefined),
  //       globalError:undefined
  //     })
  //   } else {
  //     this.setState({
  //       email: (element ? element : undefined),
  //       errorEmail: "Obligatoire !",
  //       globalError: undefined
  //     })
  //   }
  // }



  // sendMail() {
  //   if (this.isValid()) {
  //     this.setState({isLoading:true})
  //     setTimeout(() => {
  //       this.setState({isLoading:false, sended:true})
  //     }, 5000)
  //   } else {
  //     this.setState({globalError:"Tous les champs sont obligatoires et doivent être valides"})
  //   }
  // }


  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Marquage & assemblage
          </p>
        </header>
        <div className="descriptionTextContainer">
          <div className="descriptionTextRow">
            <h1>
              Marquage sur tous supports et toutes matières
            </h1>
            <p>
              <span className="superview">ABCS</span> propose des solutions de <span className="superview">marquages techniques efﬁcaces et ﬁables</span>, satisfaisant tous les projets !<br/><br/>
              Nous vous apportons des conseils techniques et un <span className="superview">accompagnement complet</span> propre à votre demande.&nbsp;


              <span className="superview">ABCS</span> réalise des marquages allant du prototypes aux moyennes séries dans le respect de vos plans et délais, des pièces les plus simples aux plus complexes.<br/><br/>


              Les  <span className="superview">déplacements sur site</span> client pour un marquage au laser ﬁbré sont possibles.
              Suivant vos instructions nous vous proposons conditionnement, comptage, ensachage et livraison.
              Notre <span className="superview">savoir-faire</span> permet une prestation sur des surfaces planes, cylindriques ou coniques avec ou sans fourniture de base.
              Nous réalisons tout type d’assemblage, travail sous binoculaire, opération de montage, d'assemblage manuel, reprise en plasturgie, ébavurage, tri, etc.<br/><br/>

              <span className="superview clickable" onClick={() => {window.location = "https://patricespiroux590486.typeform.com/to/MlQLHc"}}>N'hésitez pas à nous contacter</span> pour plus de détails.
            </p>
            <h2>
              Qualifié pour tous les secteurs d'activités
            </h2>
            <p>
              Médical <span className="doubleDash">\\</span>&nbsp;
              Astronautique et Aéronautique <span className="doubleDash">\\</span>&nbsp;
              Défense <span className="doubleDash">\\</span>&nbsp;
              Luxe <span className="doubleDash">\\</span>&nbsp;
              Art de la table <span className="doubleDash">\\</span>&nbsp;
              Industrie <span className="doubleDash">\\</span>&nbsp;
              etc.
            </p>
            <a href={Mokup} target="_blank" rel="noopener noreferrer">Voir notre plaquette</a>
          </div>
        </div>
        <div id="blob" className={"formRow " + (this.state.sended ? "close" : "open")}>
          <div className="linkContainer">
            <button onClick={() => {window.location = "https://patricespiroux590486.typeform.com/to/MlQLHc"}}>
              Faire une demande de devis
            </button>
          </div>
          <canvas/>
        </div>
        <div className="addressBox">
          <div className="addressTextRow">
            <div>
              <h1>Notre adresse</h1>
              <div>
                <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/xFmT9S5hApwChTX38">
                  Chemin des Maurapans <br/> ZI du Pré Brenot <br/> 25870 Châtillon-le-Duc
                </a>
              </div>
            </div>
          </div>
          <div className="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d832.8131215619587!2d5.99491369504427!3d47.29164059738029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDfCsDE3JzI5LjkiTiA1wrA1OSc0NS4yIkU!5e1!3m2!1sfr!2sfr!4v1545586399067"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    title="address"
                    style={{"border":0}}
                    allowFullScreen
            >
            </iframe>
          </div>
        </div>
        <div className="App-demi-header">
          <h2>Contacts</h2>
          <p>
            Tel : 06.38.71.22.19<br/>
            Email : <a href="mailTo:spiroux@abcsmarquage.fr">spiroux@abcsmarquage.fr</a>
          </p>
          <img src={laser} className="min-logo" alt="abcs icon" />
        </div>
        <div className="InfoLegals">
          ABCS - Siège social CHATILLON LE DUC(25870) - R.C.S. BESANÇON B 821 228 285 - APE 1812Z
          <br/>
          <br/>
          © Tous droits réservés à ABCS
          <br/>
          <br/>
          <div className="flatico">Laser icon made by <a href="https://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</a></div>
        </div>
      </div>
    );
  }
}

export default App;
