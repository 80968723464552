import $ from "jquery";
import SimplexNoise from 'simplex-noise'
let THREE = require('three')

var camera;

$(document).ready(function() {

    let speedSlider = 20,
        spikesSlider = 0.6,
        processingSlider = 1;

    let $canvas = $('#blob canvas'),
        $button = $('#blob button'),
        canvas = $canvas[0],
        renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            context: canvas.getContext('webgl2'),
            antialias: true,
            alpha: true
        }),
        simplex = new SimplexNoise();

    renderer.setSize($canvas.width(), $canvas.height());
    renderer.setPixelRatio(window.devicePixelRatio || 1);

    let scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(45, $canvas.width() / $canvas.height(), 0.1, 1000);

    camera.position.z = 5;

    let geometry = new THREE.SphereGeometry(.1,  128, 128);

    let material = new THREE.MeshPhongMaterial({
        color: 0xe8007d,
        shininess: 300,
    });

    let lightTop = new THREE.DirectionalLight(0xFFFFFF, .7);
    lightTop.position.set(0, 500, 200);
    lightTop.castShadow = true;
    scene.add(lightTop);

    let ambientLight = new THREE.AmbientLight(0x798296);
    scene.add(ambientLight);

    let sphere = new THREE.Mesh(geometry, material);

    scene.add(sphere);

    let update = (spikesValue = 0.2) => {

        let time = performance.now() * 0.00001 * speedSlider * Math.pow(processingSlider, 3),
            spikes = spikesValue * processingSlider;

        for(let i = 0; i < sphere.geometry.vertices.length; i++) {
            let p = sphere.geometry.vertices[i];
            p.normalize().multiplyScalar(1 + 0.3 * simplex.noise3D(p.x * spikes, p.y * spikes, p.z * spikes + time));
        }

        sphere.geometry.computeVertexNormals();
        sphere.geometry.normalsNeedUpdate = true;
        sphere.geometry.verticesNeedUpdate = true;

    }

    let currentSpike = spikesSlider
    let direction = "down"
    const maxSpike = spikesSlider * 2
    const minSpike = spikesSlider
    const stepsSpike = 0.03

    function spikeUp() {
        if (currentSpike < maxSpike)
            currentSpike += stepsSpike
    }

    function spikeDown() {
        if (currentSpike > minSpike)
            currentSpike -= stepsSpike
    }


    function animate() {
        if (direction === "up")
            spikeUp()
        else
            spikeDown()
        update(currentSpike);
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
    }

    $button.mouseover(function(){
        direction = "up"
    });


    $button.mouseleave(function(){
        direction = "down"
    });

    requestAnimationFrame(animate);

});
